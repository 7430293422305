import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
  return (
    <Layout>
      <SEO
        title="Maak kennis met het team"
        description="Initiator van de Zipper® Methode, Ellen Schoone, is een coach die gecertificeerd is door het EIA-keurmerk, waarmee zij voldoet aan strenge kwaliteitseisen"
      />

      <h1
        style={{
          textAlign: "center",
          padding: "25px 0 0 0",
          marginBottom: "0",
        }}
      >
        De 7 Tips tegen Corona
      </h1>
      <h2
        style={{
          textAlign: "center",
          padding: ".2em",
          background: "#001830",
          color: "white",
          fontSize: "1.62671rem",
          width: "75%",
          margin: "10px auto",
        }}
      >
        Zippers Corona's Advies in combinatie met NOBCO
      </h2>

      <div className="contactButtons">
        <div className="btn-container">
          <a
            href="tel:0653667567"
            className={"btn primary-lg round expand-on-hover"}
          >
            Bel nu!
          </a>
        </div>
        <div className="btn-container">
          <a
            href="https://www.google.com/maps?q=Kerkstraat+7A+5671+GN+Nuenen&rlz=1C1GCEA_enNL834NL834&um=1&ie=UTF-8&sa=X&ved=2ahUKEwi1yp_I0fvmAhUkMewKHTipBDcQ_AUoAXoECAwQAw"
            className={"btn primary-lg round expand-on-hover"}
          >
            Navigeer!
          </a>
        </div>
      </div>

      <div className="containerPad grid-system contactGrid">
        <div className={"leftContactSection"}>
          <h2>NOBCO Corona advies</h2>
          <p>
            Ook binnen de NOCBO leiden het coronavirus en de te nemen
            maatregelen tot vragen. De NOBCO houdt de berichtgeving nauwlettend
            in de gaten. Bij deze informeren we je graag over de keuzes die je
            als professioneel coach kunt maken. Zodat je zowel voor je eigen
            gezondheid als die van je coachees verantwoordelijkheid neemt. Ook
            in onze Ethische Code staat beschreven dat het jouw persoonlijke en
            professionele verantwoordelijkheid is om zorgvuldig af te wegen wat
            in een situatie gepast of noodzakelijk is.
          </p>
          <p>
            Wat betekenen de nieuwe maatregelen voor coaches en het voeren van
            coachgesprekken? De NOBCO neemt het landelijk advies over en
            adviseert daarom het volgende:
          </p>
          <p style={{ fontWeight: "bold" }}>
            De 10 tips om corona tegen te gaan.
          </p>

          <ul className={"coronaList"}>
            <li>
              Indien je verkoudheidsverschijnselen, koorts of hoestklachten
              hebt, dan raden we je aan de afspraken met je coachee(s) te
              verzetten óf via bijvoorbeeld skype te laten plaatsvinden.
            </li>
            <li>
              Neem het initiatief naar de coachees met wie je de komende week
              afspraken hebt om nu al te laten zien dat je er mee bezig bent en
              de ontwikkelingen volgt
            </li>
            <li>
              Vraag je coachee om de afspraak te verplaatsen als deze
              verkoudheidsverschijnselen, koorts of hoestklachten heeft.
            </li>
            <li>
              Schud geen handen met je coachee, er zijn voldoende andere
              manieren om iemand te begroeten en afscheid te nemen.
            </li>
            <li>
              Positioneer jezelf tijdens een coachgesprek fysiek niet binnen een
              straal van 2 meter van je coachee.
            </li>
            <li>Werk thuis, als dat kan.</li>
            <li>Vermijd plekken waar meer dan 100 mensen bij elkaar zijn.</li>
            <li>Hoest en nies in de binnenkant van je elleboog.</li>
            <li>Raak zo min mogelijk je ogen, neus en mond aan.</li>
            <li>
              Neem het zeker voor het onzekere; vertrouw je je
              kuchje/temperatuur/fitheid niet? Onderneem passend actie.
            </li>
          </ul>
          <p style={{ fontWeight: "bolder" }}>
            Tot slot, zorg goed voor jezelf en versterk je weerstand: neem je
            rust, zorg voor voldoende nachtrust, denk bijvoorbeeld aan (wat
            extra) gezonde voeding en vermijd factoren die je (extra) stress
            opleveren.
          </p>

          <h2>OMGAAN MET STRESS, ANGST EN ONZEKERHEID CORONA:</h2>

          <ol className={"StressList"}>
            <li>
              Adem
              <p>
                Wanneer je veel last hebt van stress en je hopeloos voelt of in
                paniek raakt vergeet je vaak de meest basale dingen uit te
                voeren. Wie te snel ademt krijgt last van een stijgende hartslag
                omdat je lichaam denkt een topprestatie te moeten leveren. Wie
                te langzaam of te weinig ademt krijgt niet genoeg zuurstof
                binnen, met alle problemen van dien. Trek daarom je longen
                helemaal vol met lucht, houd een seconde of twee vast en adem op
                een langzaam tempo weer uit. Niet alleen krijg je hierdoor meer
                helderheid in je hoofd, ook kalmeert je hartslag zo. Herhaal dit
                ongeveer vier keer.
              </p>
            </li>
            <li>
              Praat, schrijf of vlog het van je af
              <p>
                Breng onder woorden waar je precies mee zit. Door over je
                situatie en gedachten te schrijven verduidelijk je het probleem.
                Doe dit met tekst, want zo moet je namelijk kiezen welke woorden
                je gaat gebruiken. Zonder dat je het doorhebt dwing je je
                hersenen zo tot nadenken en concretiseer je de gevoelens. Zodra
                dit op papier staat begint de analyse. Wat zit je eigenlijk
                dwars? Wat is de aanleiding? Wat is jouw aandeel? Kun je er iets
                aan doen? Gedachten gaan van kwaad naar erger. Wanneer je ze
                niet concreet op papier zet blijven ze in je gedachten spoken.
                Hierdoor krijg je cirkelredenering en daar is moeilijk uit te
                breken. Gedachten zijn namelijk een doorgeefluik. Je pikt iets
                op uit een boek, film of liedje en past dit toe op iets, of
                vertelt erover tegen anderen. Wanneer je dit niet doet ontstaat
                er kortsluiting in je hoofd, omdat je alleen bezig bent met
                dingen opnemen, en niet met verwerken. Dit zorgt voor problemen
                in onze samenleving waarin we overspoeld worden met informatie.
                Dit leidt meteen tot tip 3
              </p>
            </li>
            <li>
              Zorg voor voldoende afleiding
              <p>
                Wandelen minstens 30 minuten per dag. Maak een lijst van zaken
                en dingen die nog in het huishouden gedaan moeten worden. Plan
                elke dag een uur om dit lijstje af te werken.
              </p>
            </li>
            <li>
              Zorg voor een goede structuur
              <p>
                Zorg dat je een structuur houdt, ook nu je misschien thuiswerkt.
                Werk niet te lang achter elkaar maar zorg dat je ieder uur een
                herstelmoment hebt waarbij je even gaat bewegen of andere
                afleiding zoekt. Ritme is belangrijk!
              </p>
            </li>
            <li>
              Focus je niet teveel op negatieve berichtgeving
              <p>
                Wanneer je de hele dag gefocust bent op al het negatieve nieuws
                gaan je hersenen ervoor zorgen dat je je depressief en negatief
                voelt. Door de negatieve gedachten krijg je negatieve gevoelens.
                Je krijgt veel irrationele gedachten en verliest de
                werkelijkheid uit het oog. Dit kost je veel energie. Dus niet
                teveel op social media. Probeer quality time met je familie in
                te bouwen door bijvoorbeeld spelletjes te spelen. Ga niet teveel
                op sociale media zitten.{" "}
              </p>
            </li>
            <li>
              Denk aan leuke dingen
              <p>
                {" "}
                Ieder mens weet hoe het is om je beter te voelen dan nu. Probeer
                die goede momenten terug te halen, door precies de situatie van
                toen te schetsen: Waar was je? Met wie? Wat deed je precies? Hoe
                voelde je je toen? Kijk naar fotoalbums en zoek naar mooie
                herinneringen. Dit noem je positieve visualisatie. Door aan
                leuke dingen te denken houd je je hersenen voor de gek, terwijl
                denken aan deprimerende zaken juist het hopeloze gevoel
                versterkt.
              </p>
            </li>
            <li>
              Persoonlijke ontwikkeling
              <p>
                De meest lastige situaties maken je sterker. Als er niets in je
                leven gebeurt hoef je je ook niet te ontwikkelen. Door moeilijke
                zaken ontwikkel je je steeds meer. Een mens is veel
                veerkrachtiger dan de meeste mensen denken. Vaak verzin je toch
                wel weer oplossingen of maak je nieuwe keuzes. Vertrouw op
                jezelf! De belangrijkste relatie heb je met jezelf. Je kunt
                altijd steun vragen aan professionals of mensen in je omgeving.
                Durf ook kwetsbaar te zijn.
              </p>
            </li>
          </ol>

          <Link to={"/traject"}>
            Klik hier voor de Trajecten van een Zipperr® traject
          </Link>
          <p>
            <strong>Is jouw belangstelling gewekt?</strong>
          </p>
          <p>Neem dan vandaag nog telefonisch contact op met Zipperr</p>
        </div>

        <div className="rightSideContactBar">
          <h3>contact gegevens</h3>
          <div className="iconsSide">
            <p>
              <a href="tel:0653667567">Tel: 06 536 675 67</a>
            </p>
            <p>
              <a href="mailto:info@zipper.nl?SUBJECT=website-contact">
                E-Mail: info@zipperr.nl
              </a>
            </p>
            <p>
              <a href="https://www.google.com/maps?q=Kerkstraat+7A+5671+GN+Nuenen&rlz=1C1GCEA_enNL834NL834&um=1&ie=UTF-8&sa=X&ved=2ahUKEwi1yp_I0fvmAhUkMewKHTipBDcQ_AUoAXoECAwQAw">
                Adres: Kerkstraat 7A <br />
                5671 GN Nuenen.
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
